import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { API_ADDRESS, PAGE_TITLE_BASE } from './../../constants.js';
import { References } from './../subcomponents/References';
import { ImageGallery } from './../subcomponents/ImageGallery';
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { Dimmer, Loader, Segment, Header, Grid, Table, Divider, Icon, Tab, Message, TableRow } from 'semantic-ui-react'
import { ReleaseBrowseCommon } from './../browse/ReleaseBrowseCommon';

export function Release (props) {
	const {id} = useParams();
	const [idUpdated, setIdUpdated] = useState(id);
	useEffect(() => { if(idUpdated !== id ) { setIdUpdated(id); setLoading(true); } }, [idUpdated, id])

	const [release, setRelease] = useState({});
	const [discIndex, setDiscIndex] = useState(0);
	const [labels, setLabels] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const search = useCallback(() => {
		fetch(`${API_ADDRESS}/release/${idUpdated}`)
			.then(function(response) {
				return response.json()
			}).then(function(json) {
				if(json.length === 0 ) {
					navigate('/404');
				}
				setRelease(json[0]);
				setDiscIndex(0);
				document.title = json[0]['title'] + ' | ' + PAGE_TITLE_BASE;

				let labels = [];
				for(var labelId in json[0]['labels']) {
					let label = json[0]['labels'][labelId];
					labels.push(<Link to={'/organisation/' + label._id} key={label._id}>{label.name}</Link>);
					labels.push(<br/>);
				}
				setLabels(labels);

				setLoading(false);
			}).catch(function(ex) {
				console.log(ex);
				navigate('/500');
			})
	}, [idUpdated, navigate]);

	useEffect(() => { if(loading ) { search() } }, [loading, search])

	const discs = [];
	if('discs' in release) {
		release.discs.sort((a,b) => { return a.position === b.position ? 0 : a.position < b.position ? -1 : 1 });
		release.discs.map(function(disc) {
			var tab = {
				menuItem: disc.name,
				render: () => <Tab.Pane>
					<Table definition inverted>
						<Table.Body>
							<Table.Row>
								<Table.HeaderCell width={2}>Format</Table.HeaderCell>
								<Table.Cell>{disc.format}</Table.Cell> 
							</Table.Row>
						</Table.Body>
					</Table>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='list ol' />
							Tracks
						</Header>
					</Divider>
					<Table definition inverted>
						<Table.Body>
							<TableRow>
								<Table.HeaderCell width={1}>Position</Table.HeaderCell>
								<Table.HeaderCell width={2}>Name</Table.HeaderCell>
								<Table.HeaderCell width={2}>Artist</Table.HeaderCell>
								<Table.HeaderCell width={2}>Length</Table.HeaderCell>
								<Table.HeaderCell width={2}>Recording</Table.HeaderCell>
								<Table.HeaderCell width={2}>Take</Table.HeaderCell>
								<Table.HeaderCell width={2}>Variant</Table.HeaderCell>
							</TableRow>
							{disc.tracks.map(function(track) {
								let name = track.name;
								let recording = '';
								let take = '';
								let variant = '';
								if('recording' in track) {
									for(var pos in track.recording.sessions) {
										if(recording !== '') { recording += '\n'; }
										let session = track.recording.sessions[pos];
										recording += session.obj.date_display;
									}
									recording = <Link to={'/recording/' + track.recording._id}>{recording}</Link>;
								} else if('film' in track) {
									recording = <Link to={'/film/' + track.film._id}>{track.film.title}</Link>;
									take = 'Film';
								} else if('television' in track) {
									recording = <Link to={'/television/' + track.television._id}>{track.television.date_display}</Link>;
									if('name' in track.television) {
										take = track.television.name;
									} else {
										take = 'Television';
									}
								} else if('radio' in track) {
									recording = <Link to={'/radio/' + track.radio._id}>{track.radio.date_display}</Link>;
									if('name' in track.radio) {
										take = track.radio.name;
									} else {
										take = 'Broadcast';
									}
								} else if('event' in track) {
									recording = <Link to={'/event/' + track.event._id}>{track.event.date_display}</Link>;
									take = track.event.type + ' excerpt';
								} else if('advert' in track) {
									recording = <Link key={track.advert._id} to={'/advert/' + track.advert._id}>{track.advert.date_display}</Link>;
									take = 'Advert (' + track.advert.type + ')';
								} else if('not_sinatra' in track) {
									recording = <i>Not Sinatra</i>;
								}
								if('take' in track) {
									take = <Link to={'/recording/' + track.recording._id}>{track.take.name}</Link>;
									if('extra-recordings' in track.take) {
										let recordings = [recording];
										for(var er in track.take['extra-recordings']) {
											let tmpRec = '';
											for(pos in track.take['extra-recordings'][er]['sessions']) {
												if(tmpRec !== '') { tmpRec += '\n'; }
												let session = track.take['extra-recordings'][er]['sessions'][pos];
												tmpRec += session.obj.date_display;
											}
											recordings.push(<br/>);
											recordings.push(<Link to={'/recording/' + track.take['extra-recordings'][er]['_id']}>{tmpRec}</Link>);
										}
										recording = <span>{recordings}</span>
									}
								}
								if('variant' in track) {
									variant = <span>{track.variant.name}</span>;
									if('takes' in track.variant && track.variant.takes.length > 1) {
										let takes = [];
										track.variant.takes.map(function(take) {
											if(takes.length > 0) {
												takes.push(<br/>);
											}
											takes.push(<Link to={'/recording/' + take.recording}>{take.name}</Link>);
											return take;
										});
										take = <span>{takes}</span>;

										if('extra-recordings' in track.variant) {
											let recordings = [recording];
											for(er in track.variant['extra-recordings']) {
												let tmpRec = '';
												for(pos in track.variant['extra-recordings'][er]['sessions']) {
													if(tmpRec !== '') { tmpRec += '\n'; }
													let session = track.variant['extra-recordings'][er]['sessions'][pos];
													tmpRec += session.obj.date_display;
												}
												recordings.push(<br/>);
												recordings.push(<Link to={'/recording/' + track.variant['extra-recordings'][er]['_id']}>{tmpRec}</Link>);
											}
											recording = <span>{recordings}</span>
										}
									}
								} else if('film_variant' in track) {
									variant = <span>{track.film_variant.name}</span>;
								} else if('television_variant' in track) {
									variant = <span>{track.television_variant.name}</span>;
								} else if('radio_variant' in track) {
									variant = <span>{track.radio_variant.name}</span>;
								} else if('event_variant' in track) {
									variant = <span>{track.event_variant.name}</span>;
								} else if('advert_variant' in track) {
									variant = <span>{track.advert_variant.name}</span>;
									if(track.advert_variant.adverts) {
										let recordings = [recording];
										track.advert_variant.adverts.map(function(advert) {
											recordings.push(<br key={'br_' + advert._id}/>);
											recordings.push(<Link key={advert._id} to={'/advert/' + advert._id}>{advert.date_display}</Link>);
											return advert._id;
										});
										recording = <>{recordings}</>;
									}
								}
								if(('radio' in track && 'tracks' in track.radio) || ('television' in track && 'tracks' in track.television)
									|| ('film' in track && 'tracks' in track.film)
								) {
									let recordingDates = '';
									let songNames = '';
									let takeNames = '';
									let variantNames = '';
									let trackPos = 0;
									let rTracks = [];
									if('radio' in track) {
										rTracks = track.radio.tracks;
									} else if('television' in track) {
										rTracks = track.television.tracks;
									} else if('film' in track) {
										rTracks = track.film.tracks;
									}
									rTracks.map(function(rTrack) {
										if('take' in rTrack && 'recording' in rTrack) {
											trackPos++;
											if(recordingDates !== '') { recordingDates += '\n'; }
											recordingDates += '↳ ' + trackPos + ') ';
											for(var pos in rTrack.recording.sessions) {
												if(pos > 0) { recordingDates += ', '; }
												let session = rTrack.recording.sessions[pos];
												recordingDates += session.obj.date_display;
											}
											if(songNames !== '') { songNames += '\n'; }
											songNames += '↳ ' + trackPos + ') ' + rTrack.name;
											if(takeNames !== '') { takeNames += '\n'; }
											takeNames += '↳ ' + trackPos + ') ' + rTrack.take.name;
											if('variant' in rTrack) {
												if(variantNames !== '') { variantNames += '\n'; }
												variantNames += '↳ ' + trackPos + ') ' + rTrack.variant.name;
											} else {
												variantNames += '\n';
											}
											if('excerpt' in rTrack || 'excerpt-unknown' in rTrack) {
												let excerpt = '';
												if('excerpt' in rTrack) {
													excerpt = 'Excerpt details: ' + rTrack.excerpt;
												} else {
													excerpt = 'Excerpt details: unknown';
												}
												if(variantNames) {
													variantNames += '\n' + excerpt;
												} else {
													variantNames = excerpt;
												}
											}
										}
										return rTrack.pos;
									});
									recording = <>{recording}<br/>{recordingDates}</>;
									name = <>{name}<br/>{songNames}</>;
									take = <>{take}<br/>{takeNames}</>;
									variant = <>{variant}<br/>{variantNames}</>;
								}
								if('info_wanted' in track) {
									recording = <span>Information wanted</span>
								}
								return <Table.Row key={track.position}>
									<Table.Cell>{track.position}</Table.Cell>
									<Table.Cell>{name}{'actual_name' in track && <><br/><i>[corrected: {track.actual_name}]</i></>}</Table.Cell> 
									<Table.Cell>{track.artist}{'actual_artist' in track && <><br/><i>[corrected: {track.actual_artist}]</i></>}</Table.Cell> 
									<Table.Cell>{track.length}</Table.Cell> 
									<Table.Cell>{recording}</Table.Cell>
									<Table.Cell>{take}</Table.Cell> 
									<Table.Cell>{variant}</Table.Cell> 
								</Table.Row>
							})}
						</Table.Body>
					</Table>
				</Tab.Pane>
			};
			discs.push(tab);
			return tab;
		});
	}
	const related_releases = [];
	if('albums' in release) {
		release.albums.map(function(album) {
			if(!isNaN(album)) { return <></>;} 
			var album_name = album.type + ": ";
			if('series' in album) {
				album_name = 'Series: ';
			} else if('single' in album) {
				album_name = 'Single: ';
			} else if('ep' in album) {
				album_name = 'EP: ';
			} else if('film' in album) {
				album_name = 'Film: ';
			} else if('television' in album) {
				album_name = 'Television: ';
			} else if('radio' in album) {
				album_name = 'Radio: ';
			} else if('concert' in album) {
				album_name = 'Concert: ';
			}
			album_name += album.title;
			var tab = {
				menuItem: album_name,
				render: () => <Tab.Pane>
					<ReleaseBrowseCommon key={'album_' + album._id + '_release_' + release._id} album={album._id} release={id} urlParams={false}/>
				</Tab.Pane>
			};
			related_releases.push(tab);
			return tab;
		});
	}
	if('labels' in release) {
		release.labels.map(function(label) {
			var tab = {
				menuItem: 'Label: ' + label.name,
				render: () => <Tab.Pane>
					<ReleaseBrowseCommon key={'label_' + label._id} label={label._id} release={id} urlParams={false}/>
				</Tab.Pane>
			};
			related_releases.push(tab);
			return tab;
		});
	}

	let images = <ImageGallery images={release.images} type="releases" aspectRatio="4 / 3" key={release._id}/>;

	return (
		<Segment inverted style={{minHeight:'500px', margin:'1%'}}>
			<Dimmer active={loading}>
				<Loader size='large'>Loading</Loader>
			</Dimmer>
			<Grid stackable columns={2}>
				{'pending' in release &&
					<>
						<Grid.Row>
							<Message warning style={{textAlign:'center'}}>
								<Message.Header><br/>Release and recording pages are still being re-organised/reviewed for accuracy - not easy with over 70,000 entires.</Message.Header>
							</Message>
						</Grid.Row>
						<Grid.Row>
							<Message warning style={{textAlign:'center'}}>
								<Message.Header><br/>This page is marked as pending, which means the discographical accuracy and the exact recordings used on the release is being cross-referenced.<br/><br/>Note this page is yet to be reviewed for adaptation to the updated Sinatraology user interface</Message.Header>
							</Message>
						</Grid.Row>
					</>
				}
				{'info_wanted' in release &&
					<Message negative icon>
						<Message.Header><Icon name='help icon'/> Information Wanted</Message.Header>
						<Message.Content>
							We need more information to determine the recording dates for the following tracks:
							<ul>
								{release.info_wanted.map(function(track) {
									return <li>{track.disc_name} track {track.position} - {track.name}</li>
								})}
							</ul>
							If you have any information, please contact us at <a href="contact:admin@sinatraology.com">admin@sinatraology.com</a>.
						</Message.Content>
					</Message>
				}
				<Grid.Row>
					<Header as='h1' inverted style={{textAlign:'center'}}>{release['title']}</Header>
				</Grid.Row>
				<Grid.Row columns={2} stackable>
					<Grid.Column>
						<Divider horizontal inverted>
							<Header as='h4' inverted>
								<Icon name='info circle' />
								Release Details
							</Header>
						</Divider>
						<Table definition inverted>
							<Table.Body>
								{'artist' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Artist</Table.HeaderCell>
										<Table.Cell>{release.artist}</Table.Cell> 
									</Table.Row>
								}
								{labels &&
									<Table.Row>
										<Table.HeaderCell width={2}>Label</Table.HeaderCell>
										<Table.Cell>{labels}</Table.Cell> 
									</Table.Row>
								}
								{('country' in release && release.country !== '[not applicable]') &&
									<Table.Row>
										<Table.HeaderCell width={2}>Country</Table.HeaderCell>
										<Table.Cell>{release.country}</Table.Cell> 
									</Table.Row>
								}
								{'status' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Status</Table.HeaderCell>
										<Table.Cell>{release.status}</Table.Cell> 
									</Table.Row>
								}
								{'date_display' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Release Date</Table.HeaderCell>
										<Table.Cell>{release.date_display}</Table.Cell> 
									</Table.Row>
								}
								{'format' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Format</Table.HeaderCell>
										<Table.Cell>{release.format}</Table.Cell> 
									</Table.Row>
								}
								{'catalog' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Catalog</Table.HeaderCell>
										<Table.Cell>{release.catalog}</Table.Cell> 
									</Table.Row>
								}
								{'isbn' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>ISBN</Table.HeaderCell>
										<Table.Cell>{release.isbn}</Table.Cell> 
									</Table.Row>
								}
								{'barcode' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Barcode</Table.HeaderCell>
										<Table.Cell>{release.barcode}</Table.Cell> 
									</Table.Row>
								}
								{'pressing' in release &&
									<Table.Row>
										<Table.HeaderCell width={2}>Pressing</Table.HeaderCell>
										<Table.Cell>{release.pressing}</Table.Cell> 
									</Table.Row>
								}
							</Table.Body>
						</Table>
						{'notes' in release &&
							<p><b>Notes</b><br/> <span dangerouslySetInnerHTML={{__html: release.notes}}></span></p> 
						}
					</Grid.Column>
					<Grid.Column>
						<Divider horizontal inverted>
							<Header as='h4' inverted>
								<Icon name='picture' />
								Images
							</Header>
						</Divider>
						{images}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Divider horizontal inverted>
						<Header as='h4' inverted>
							<Icon name='radio' />
							Discs
						</Header>
					</Divider>
					<Tab panes={discs} menu={{ inverted: true, attached: true, tabular: true }} activeIndex={discIndex} onTabChange={(event, data) => setDiscIndex(data.activeIndex)}/>
				</Grid.Row>
				{related_releases.length > 0 &&
					<Grid.Row>
						<Divider horizontal inverted>
							<Header as='h4' inverted>
								<Icon name='tags' />
								Related Releases
							</Header>
						</Divider>
						<Tab panes={related_releases} menu={{ inverted: true, attached: true, tabular: true }} />
					</Grid.Row>
				}
				{(('references' in release && release.references.length > 0) || 'acknowledgements' in release) &&
					<Grid.Row>
						<References references={release.references} acknowledgements={release.acknowledgements}/>
					</Grid.Row>
				}
			</Grid>
		</Segment>
	);
}
import React from 'react';

export function Footer(props) {
	return (
		<div style={{textAlign: 'center'}}>
			<hr style={{marginTop: '10px'}}/>
			<p>
				Contact <a href="mailto:admin@sinatraology.com">admin@sinatraology.com</a> with questions, feedback, or corrections<br/>
				<small>&copy; 2025 Richard Cook</small>
			</p>
		</div>
	);
} 